import {config} from '../config';
import {rocketUserSession} from "./rocketUserSession";

import {api} from "./api";
let socket;
let _msgCallback;

export const rocketApi = {
  login: async (userToken) => {
    if (!rocketUserSession.getAuthToken()) {  //FIXME: There's a slight chance that the token is expired, it expires after 90 days
      try {
        const {rocketUserId, rocketAuthToken} = await api.chatSvcRequest("GET", "/rocketAuthTokens/1", null, null, userToken);
        rocketUserSession.setAuthToken(rocketAuthToken);
        rocketUserSession.setUserId(rocketUserId);
      } catch (e) {
        throw new Error(`Error retrieving rocket chat userId and authToken. ${e}`);
      }
    }
    return rocketUserSession;
  },

  retrieveMessagesInPrivateRoom: async (roomId, count, offset) => {
    _msgCallback(await rocketApiRequest("GET", `/groups.history?roomId=${roomId}&count=${count}&unreads=true${offset ? `&offset=${offset}` : ""}`))
  },

  sendMessage: async (msg) => {
    return rocketApiRequest("POST", `/chat.postMessage`, msg);
  },

  uploadFile: async (roomId, photo) => {
    const data = new FormData();

    const uriSplit = photo.uri.split('/');
    const name = uriSplit[uriSplit.length - 1];

    const extensionArr = photo.uri.split('.');
    const extension = extensionArr[extensionArr.length - 1];

    data.append('file', {
      name,
      filename: name,
      'Content-Disposition': 'form-data',
      type: `${photo.type}/${extension}`,
      uri: Platform.OS === 'android' ? photo.uri : photo.uri.replace('file://', ''),
    });

    return rocketApiRequest("POST", `/rooms.upload/${roomId}`, data, {'Content-type': 'multipart/form-data'})
  },

  markRoomAsRead: async (rid) => {
    return rocketApiRequest("POST", `/subscriptions.read`, {rid});
  },

  getSubscription: async (roomId) => {
    return rocketApiRequest('GET', `/subscriptions.getOne?roomId=${roomId}`)
  },

  chatAssetSource: (channelId, uri) => {
    return {
      uri,
      headers: {
        "x-user-id": rocketUserSession.getUserId(),
        "x-auth-token": rocketUserSession.getAuthToken(),
        "Cookie": `rc_uid=${rocketUserSession.getUserId()}; rc_token=${rocketUserSession.getAuthToken()}; rc_is_widget=t; rc_room_type=l; rc_rid=${channelId}`
      }
    }
  },

  socketConnect: async () => {
    if (socket && socket.readyState === 1) return;
    socket = new WebSocket(config.chatServer.baseSocketsUrl);
    return new Promise(((resolve, reject) => {
      socket.onopen = () => {
        socket.send(JSON.stringify({ msg: "connect", version: "1", support: ["1", "pre2", "pre1"]}));
        socket.send(JSON.stringify({
          msg: "method",
          method: "login",
          id: rocketUserSession.getUserId(),
          params: [{
            resume: rocketUserSession.getAuthToken()
          }]
        }));
        resolve("ok");
      };
      socket.onmessage = (ev) => {
        const data = JSON.parse(ev.data);
        if (data.msg === "ping") {
          socket.send(JSON.stringify({"msg": "pong"}));
          return;
        }
        if (_msgCallback && data.collection === "stream-room-messages") {
          _msgCallback(data.fields.args);
        }
      };
      socket.onclose = (ev) => {console.log(ev)}; //TODO: maybe we should do something smarter than this
    }));
  },

  setMessageCallback: (msgCallback) => {
    _msgCallback = msgCallback;
  },

  subscribeToRoomMessages: (roomId) => {
    if (!socket) return;

    socket.send(JSON.stringify({
      "msg": "sub",
      "id": "subscribing-to-CS",
      "name": "stream-room-messages",
      "params": [
        `${roomId}`,
        false
      ]
    }));
  }
}

async function rocketApiRequest( method, path, payload, headers) {
  const options = {
    headers: {
      "X-Auth-Token": rocketUserSession.getAuthToken(),
      "X-User-Id": rocketUserSession.getUserId(),
      ...headers
    },
    method,
    body: payload
  };

  if (!Object.keys(options.headers).map(key => key.toLowerCase()).includes('content-type')) {
    options.headers["Content-type"] = "application/json";
    if (payload) {
      options.body = JSON.stringify(payload);
    }
  }

  return fetch(`${config.chatServer.baseRestUrl}${path}`, options)
  .then((response) => {
    if (!response.ok) {
      //TODO: Logic to deal with 401s / 403s, we should retry logging in. I'm not completely sure it's 401 / 403 what we get from the server if we pass an expired token, though, double-check
      return Promise.reject(response);
    }
    if (response.status === 204) {
      return null;
    }
    return response.json();
  });
}
