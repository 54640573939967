export const palette = {
  $mainColor1: "#fee773",
  $mainColor2: "#ffa23e",

  $primary: "#F2F7FD",

  $accent1Tint2: "#e6effc",
  $accent1Tint1: "#bedbff",
  $accent1: "#a5c0e5",
  $accent1Shade1: "#84a5d1",
  $accent1Shade2: "#455d89",
  $accent1Shade3: "#2d3037",

  $grey: "#DDDDDD",
  $redText: "#D8000C",
  $greyText: "#919191",
  $accent3: "#2C7BF4",
  $red: "#E52325",
  $yellow: "#FFD86A",
  $orange: "#EF734E",
  $lightRed: "#ffe6e9",
  //Can you guys please stop adding random colours here?
};
