let _rocketAuthToken = null;
let _chatUserId = null;

export const rocketUserSession = {
  setUserId: (userId) => {
    _chatUserId = userId;
  },

  setAuthToken: (authToken) => {
    _rocketAuthToken = authToken;
  },

  getUserId: () => {
    return _chatUserId;
  },

  getAuthToken: () => {
    return _rocketAuthToken;
  }
}