import React, {useEffect, useState} from "react";
import { Image } from "react-native";

export function NetworkImage(props) {
  const [blobUri, setBlobUri] = useState(null);

  useEffect(() => {loadImage()}, []);

  async function loadImage() {
    try {
      const res = await fetch(props.source.uri, {
        method: "GET",
        headers: {
          ...props.source.headers,
        }}
      );
      const blob = await res.blob();
      var reader = new FileReader();
      reader.onloadend = function() {
        setBlobUri(reader.result);
      }
      reader.readAsDataURL(blob);
    } catch (e) {
      console.error("Failed to load image", e);
    }
  }

  if (blobUri) {
    return <Image {...props} source={{ uri: blobUri }} />;
  } else {
    return null;
  }
}
