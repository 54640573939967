import React, {useEffect, useRef, useState} from 'react';
import {AppState, View, TouchableOpacity, Platform} from 'react-native';
import {GiftedChat} from "react-native-gifted-chat";
import {chatSession} from "./chatSession";
import Lightbox from "react-native-lightbox-v2";  //This is a transitive dependency of react-native-gifted-chat, so it should be present
import {rocketEmojiToUnicode} from "./rocketEmojiToUnicode";
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {palette} from '../style/palette';
import * as ImagePicker from 'expo-image-picker';
import {NetworkImage as Image} from "./networkImage";
import {rocketUserSession} from '../api/rocketUserSession';
import _ from 'lodash';

export function Chat({occluded, shouldDisplayUserNames}) {
  const [messagesById, setMessagesById] = useState({});
  const [chatUserId, setChatUserId] = useState("");
  const [isLoadingEarlier, setLoadingEarlier] = useState(true);
  const [isVisible, setVisible] = useState(false);

  const appState = useRef(AppState.currentState);
  
  //Listen to new messages coming
  //Retrieve messages every time the app comes to the foreground
  useEffect(() => {
    chatSession.addMessageListener(messagesReceived);
    const removeAppStateListener = AppState.addEventListener("change", newState => {
      if (
        appState.current.match(/inactive|background/) &&
        newState === "active"
      ) {
        retrieveMessages();
      }
      appState.current = newState;
    });
    return () => {
      removeAppStateListener && removeAppStateListener();
    }
  }, []);
  
  //Retrieve messages every time the Chat component is shown
  useEffect(() => {
    if (!occluded) {
      retrieveMessages();
    }
    setVisible(!occluded);
  }, [occluded]);
  
  
  async function retrieveMessages() {
    if (!rocketUserSession.getUserId()) return;
    setChatUserId(rocketUserSession.getUserId());
    try {
      await chatSession.retrieveMessages();
      setLoadingEarlier(false);
    } catch (e) {
      chatSession._onError("Failed to retrieve messages", e);
    }
  }

  async function loadEarlier() {
    setLoadingEarlier(true);
    try {
      await chatSession.loadEarlierMessages();
      setLoadingEarlier(false);
    } catch (e) {
      chatSession._onError("Failed to retrieve earlier messages", e);
    }
  }

  async function sendMessages(msgs) {
    msgs.forEach(m => chatSession.sendMessage(convertGiftedMsgToRocketMsg(m)));
  }

  async function messagesReceived(msgs) {
    const newMsgs = groupById(
      msgs.map(m => convertRocketMsgToGiftedMsg(m, shouldDisplayUserNames))
    );
    setMessagesById(messagesById => ({...messagesById, ...newMsgs}));
    //hack to access the updated occluded prop value, since otherwise the value is fixed when the listener function is passed to the chatSession on component mount and never updated
    setVisible(visible => {
      (async () => {
        if (AppState.currentState === "active" && visible && chatSession.getUnreadMessagesCount() > 0) { //prevent infinite loops by checking if there are actually any messages to mark as read
          await markRoomAsReadDebounced();
        }
      })();
      return visible;
    });
  }

  const markRoomAsReadDebounced = _.debounce(async function () {
    await chatSession.markRoomAsRead();    
  }, 500);
    
  function renderActions() {
    return (
      <View style={{flexDirection: 'row', paddingBottom: 10, paddingLeft: 10}}>
        {Platform.OS !== "web" && <TouchableOpacity onPress={handleImagePick}>
          <MaterialCommunityIcons name="image" size={24} color={palette.$accent1Shade3}/>
        </TouchableOpacity>}
      </View>
    )
  }

  const handleImagePick = async () => {
    let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("Permission to access media files is required!");
      return;
    }

    const document = await ImagePicker.launchImageLibraryAsync();
    if (document.cancelled !== true) {
      await chatSession.uploadFileToRoom(document);
    }
    //TODO: show some option to retry to the user if the uploading fails
  }

  return (
    <View style={{flex: 1}}>
      <GiftedChat
        messages={Object.values(messagesById).sort((m1, m2) => m1.createdAt.valueOf() < m2.createdAt.valueOf() ? 1 : -1)}
        renderUsernameOnMessage={shouldDisplayUserNames}
        onSend={sendMessages}
        user={{_id: chatUserId}}
        wrapInSafeArea={true}
        loadEarlier={true}
        alwaysShowSend={true}
        onLoadEarlier={loadEarlier}
        isLoadingEarlier={isLoadingEarlier}
        renderActions={renderActions}
        renderMessageImage={renderMessageImage}
      />
    </View>
  )
}

function renderMessageImage({currentMessage}) {
  const assetSource = chatSession.getChatAssetSource(currentMessage.image);
  return (
    <View style={{height: 300, width: 250}}>
      <Lightbox>
        <Image
          style={{height: "100%", width: "100%", minHeight: 300}}
          resizeMode="contain"
          source={assetSource}
        />
      </Lightbox>
    </View>
  );
}

function convertRocketMsgToGiftedMsg({_id, msg, ts, u, attachments}, shouldDisplayUserNames) {
  let image
  if (attachments && attachments.length > 0) {
    if (attachments[0].image_url) {
      image = `https://chat${__DEV__ ? "-dev" : ""}.cerah.co${attachments[0].title_link}`;
    }
  }
  return {
    _id,
    text: rocketEmojiToUnicode(msg),
    createdAt: new Date(typeof ts === "string" ? ts : ts.$date),
    image,
    user: {
      _id: u._id,
      name: u.name,
      avatar: shouldDisplayUserNames ? `https://chat-dev.cerah.co/avatar/${u.username}?format=png&size=50` : 'https://public-img-cerah.s3.ap-southeast-1.amazonaws.com/cerah2.png',
    }
  }
}

function convertGiftedMsgToRocketMsg({_id, text, ...args}) {
  return {
    text
  }
}

function groupById(array) {
  const byId = {};
  array.forEach(el => byId[el._id] = el);
  return byId;
}
