const env = __DEV__ ? "dev" : "prod";

const devConfig = Object.freeze({
  CHAT_SVC_BASE_URL: "https://api-dev.cerah.co/chat",
  chatServer: {
    baseRestUrl: "https://chat-dev.cerah.co/api/v1",
    baseSocketsUrl: "wss://chat-dev.cerah.co/websocket",
  }
});

const prodConfig = Object.freeze({
  CHAT_SVC_BASE_URL: "https://api.cerah.co/chat",
  chatServer: {
    baseRestUrl: "https://chat.cerah.co/api/v1",
    baseSocketsUrl: "wss://chat.cerah.co/websocket",
  }
});

export const config = (env === "prod") ? prodConfig : devConfig;
